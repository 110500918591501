<template>
  <div class="con-wrap">
    <CarrotTitle title="팀 업적 평가 관리">
      - 본인 산하 직원의 PBC/KPI/MCG/MCR을 확인하고 평가할 수 있는 페이지
      입니다.<br />
      - MCG/MCR [View] 클릭 시 평가 페이지로 이동합니다.
    </CarrotTitle>
    <div class="board">
      <div class="mt-40">
        <div>
          <div v-if="bbs.isLoaded == true">
            <div class="mb-20">
              <select
                name="syear"
                id="syear"
                class="w-200px"
                v-model="bbs.syear"
                @change="bbs.doSearch()"
              >
                <option :value="v" v-for="(v, k) in bbs.search_years" :key="k">
                  {{ v }}
                </option>
              </select>
              <carrot-position
                v-if="bbs.info.idx_position <= 6104"
                class="w-200px ml-10"
                v-model="bbs.idx_position"
                @change="bbs.doSearch"
              ></carrot-position>
              <carrot-sector
                v-if="bbs.isLoaded == true"
                v-model="bbs.idx_sector"
                class="w-200px ml-10"
                @change="bbs.doSearchSector()"
              ></carrot-sector>
              <carrot-dept
                v-if="bbs.isLoaded == true"
                :idx_sector="bbs.idx_sector"
                v-model="bbs.idx_office"
                class="w-200px ml-10"
                @change="bbs.doSearchTeam()"
              ></carrot-dept>
              <carrot-team
                v-if="bbs.isLoaded == true"
                :idx_sector="bbs.idx_sector"
                :idx_office="bbs.idx_office"
                v-model="bbs.idx_team"
                class="w-200px ml-10"
                @change="bbs.changeTeam()"
              ></carrot-team>
              <div class="clear"></div>
            </div>

            <table class="table-col">
              <!-- <colgroup>
                                <col width="80">
                                <col width="150">
                                <col width="150">
                                <col width="150">
                                <col width="160">
                                <col width="150">
                                <col width="110">
                                <col width="110">
                                <col width="110">
                                <col width="110">
                            </colgroup> -->
              <thead>
                <tr>
                  <th width="70">No</th>
                  <th>부문</th>
                  <th>본부</th>
                  <th>팀</th>
                  <th>팀원</th>
                  <th>직급</th>
                  <th>입사일</th>
                  <th>PBC</th>
                  <th>MCG/MCR</th>
                  <th>KPI</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(irow, i) in bbs.list" :key="i">
                  <td>{{ irow.num }}</td>
                  <td>
                    <p class="line-clamp-1">{{ irow.sector_name }}</p>
                  </td>
                  <td>
                    <p class="line-clamp-1">{{ irow.office_name }}</p>
                  </td>
                  <td>
                    <p class="line-clamp-1">{{ irow.team_name }}</p>
                  </td>
                  <td>
                    <p class="line-clamp-1">
                      {{ irow.ename }}({{ irow.kname }})
                    </p>
                  </td>
                  <td>
                    <p class="line-clamp-1">{{ irow.position_name }}</p>
                  </td>
                  <td>{{ irow.joindate }}</td>
                  <!-- 마스터권한 또는 (부문/본부/팀+직급 체크)-->
                  <td
                    v-if="
                      store.state.isMaster ||
                      (irow.auth.dept && irow.auth.position)
                    "
                  >
                    <span
                      class="btn-view"
                      v-if="irow.pbc.is_view == 'Y'"
                      @click="bbs.showPBC(irow.pbc.idx)"
                      >{{ irow.pbc.state_txt }}</span
                    >
                    <span v-else>-</span>
                  </td>
                  <td v-else>-</td>
                  <td
                    v-if="
                      store.state.isMaster ||
                      (irow.auth.dept && irow.auth.position) ||
                      store.state.pd_auth
                    "
                  >
                    <span class="btn-view" @click="bbs.showMCGMCR(irow.idx)"
                      >View</span
                    >
                  </td>
                  <td v-else>-</td>
                  <td
                    v-if="
                      store.state.isMaster ||
                      (irow.auth.dept && irow.auth.position)
                    "
                  >
                    <span
                      class="btn-view"
                      v-if="irow.kpi.is_view == 'Y'"
                      @click="bbs.showKPI(irow.kpi.idx)"
                      >{{ irow.kpi.state_txt }}</span
                    >
                    <span v-else>-</span>
                  </td>
                  <td v-else>-</td>
                </tr>
                <!-- <tr v-if="bbs.idx_team=='0'">
                                    <td colspan="9">본부 & 팀을 선택해 주세요.</td>
                                </tr> -->
                <!-- <tr v-if="bbs.idx_team!='0'&&bbs.total==0">
                                    <td colspan="9">평가할 팀원이 없습니다.</td>
                                </tr> -->
                <tr v-if="bbs.total == 0">
                  <td colspan="9">정보가 없습니다.</td>
                </tr>
              </tbody>
            </table>
          </div>

          <CarrotPaging
            v-if="bbs.total > 0"
            :total="bbs.total"
            :list_per_page="bbs.rows"
            v-model="bbs.page"
            @change="bbs.doSearch()"
          ></CarrotPaging>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { onMounted, onUnmounted, reactive } from "vue";
import { useRouter, useRoute } from "vue-router";
import axios from "@/plugins/axios.js";

import CarrotSector from "@/components/common/CarrotSector.vue";
import CarrotDept from "@/components/common/CarrotDept.vue";
import CarrotTeam from "@/components/common/CarrotTeam.vue";
import Swal from "sweetalert2";
import { useStore } from "vuex";

export default {
  layout: "myPIMS",
  components: {
    CarrotDept,
    CarrotTeam,
    CarrotSector,
  },
  setup() {
    const router = new useRouter();
    const route = new useRoute();
    const store = useStore();

    const bbs = reactive({
      isLoaded: false,
      isAuth: "N",

      search_years: [],

      page: 1,
      rows: 20,

      info: {},
      list: [],
      total: 0,

      syear: "",
      idx_position: 0,

      idx_sector: 0,
      idx_office: 0,
      idx_team: 0,

      count: 0,
      doSearchSector: () => {
        bbs.idx_office = 0;
        bbs.idx_team = 0;
        bbs.doSearch();
        sessionStorage.setItem(
          "TeamEvaluationManagementList",
          JSON.stringify({
            page: bbs.page,
            rows: bbs.rows,
            syear: bbs.syear,
            idx_position: bbs.idx_position,
            idx_office: bbs.idx_office,
            idx_team: bbs.idx_team,
            idx_sector: bbs.idx_sector,
          })
        );
      },
      doSearchTeam: () => {
        bbs.idx_team = 0;
        bbs.doSearch();
        sessionStorage.setItem(
          "TeamEvaluationManagementList",
          JSON.stringify({
            page: bbs.page,
            rows: bbs.rows,
            syear: bbs.syear,
            idx_position: bbs.idx_position,
            idx_office: bbs.idx_office,
            idx_team: bbs.idx_team,
            idx_sector: bbs.idx_sector,
          })
        );
      },

      changeTeam: () => {
        if (Number(bbs.idx_team) > 0) {
          bbs.doSearch();
        }
        sessionStorage.setItem(
          "TeamEvaluationManagementList",
          JSON.stringify({
            page: bbs.page,
            rows: bbs.rows,
            syear: bbs.syear,
            idx_position: bbs.idx_position,
            idx_office: bbs.idx_office,
            idx_team: bbs.idx_team,
            idx_sector: bbs.idx_sector,
          })
        );
      },

      showPBC: (idx) => {
        sessionStorage.setItem(
          "TeamEvaluationManagementList",
          JSON.stringify({
            idx: idx,
            page: bbs.page,
            rows: bbs.rows,
            syear: bbs.syear,
            idx_position: bbs.idx_position,
            idx_office: bbs.idx_office,
            idx_team: bbs.idx_team,
            idx_sector: bbs.idx_sector,
          })
        );
        router.push({
          name: "myPIMS-PBCev-idx",
          params: {
            idx: idx,
            page: bbs.page,
            rows: bbs.rows,
            syear: bbs.syear,
            idx_position: bbs.idx_position,
            idx_office: bbs.idx_office,
            idx_team: bbs.idx_team,
            idx_sector: bbs.idx_sector,
          },
        });
      },

      showMCGMCR: (idx) => {
        sessionStorage.setItem(
          "TeamEvaluationManagementList",
          JSON.stringify({
            idx: idx,
            page: bbs.page,
            rows: bbs.rows,
            syear: bbs.syear,
            idx_position: bbs.idx_position,
            idx_office: bbs.idx_office,
            idx_team: bbs.idx_team,
            idx_sector: bbs.idx_sector,
          })
        );
        router.push({
          name: "myPIMS-MCRMCGView-idx",
          params: { idx: idx },
        });
      },

      showKPI: (idx) => {
        sessionStorage.setItem(
          "TeamEvaluationManagementList",
          JSON.stringify({
            idx: idx,
            page: bbs.page,
            rows: bbs.rows,
            syear: bbs.syear,
            idx_position: bbs.idx_position,
            idx_office: bbs.idx_office,
            idx_team: bbs.idx_team,
            idx_sector: bbs.idx_sector,
          })
        );
        router.push({
          name: "myPIMS-KPIev-idx",
          params: {
            idx: idx,
            page: bbs.page,
            rows: bbs.rows,
            syear: bbs.syear,
            idx_position: bbs.idx_position,
            idx_office: bbs.idx_office,
            idx_team: bbs.idx_team,
            idx_sector: bbs.idx_sector,
          },
        });
      },

      doSearch: () => {
        if (bbs.isLoaded == false) return;

        let params = {
          page: bbs.page,
          rows: bbs.rows,
          syear: bbs.syear,
          idx_position: bbs.idx_position,
          idx_sector: bbs.idx_sector,
          idx_office: bbs.idx_office,
          idx_team: bbs.idx_team,

          user_position: store.state.idx_position,
          user_sector: store.state.idx_sector,
          user_office: store.state.idx_office,
          user_team: store.state.idx_team,
        };

        //if( bbs.count > 4 ) return;

        axios
          .get("/rest/mypims/getTeamEVList", { params: params })
          .then((res) => {
            if (res.data.err == 0) {
              bbs.list = res.data.list;
              bbs.total = res.data.total;
            } else {
              console.info(res.data.err_msg);
            }
            //bbs.count++;
          });
      },

      doSearchMy: () => {
        axios.get("/rest/mypims/getMyInfo", { params: {} }).then((res) => {
          if (res.data.err == 0) {
            bbs.info = res.data;
            bbs.idx_sector = res.data.idx_sector;
            bbs.idx_office = res.data.idx_office;
            bbs.idx_team = res.data.idx_team;
            bbs.idx_staff = res.data.idx;
            bbs.isAuth = res.data.is_auth;
            // 경영기획본부 권한 추가
            if (bbs.isAuth == "N" && store.state.pd_auth == false) {
              bbs.isLoaded = false;
              Swal.fire({
                title: "팀 업적 평가 관리",
                text: "접근 권한이 없습니다.",
              }).then(() => {
                router.go(-1);
              });
              return;
            }

            bbs.isLoaded = true;
            bbs.doSearch();
          }
        });
      },

      makeSearchYears: () => {
        let cd = new Date();
        let cy = cd.getFullYear();
        for (let i = cy; i > 2001; i--) {
          bbs.search_years.push(i);
        }
      },
    });

    onMounted(() => {
      // Mounted
      // 경영기획본부 권한 추가
      if (store.state.position != "리드" && store.state.pd_auth == false || !store.state.isLead ) {
        //if( store.state.position != '리드' &&  1 != 1  ) {
        router.go(-1);
        
        return;
      }
      //초기 로딩시 본인의 부서/팀 선택될 수 있도록 처리.
      // bbs.idx_office = store.state.idx_office;
      // bbs.idx_team = store.state.idx_team;

      let td = new Date();
      bbs.syear = td.getFullYear();

      /*
            let ss = sessionStorage.getItem('TeamEvaluationManagementList');
            if( ss != '' && typeof ss != "undefined" && ss != null && typeof history.state.forward != 'undefined' && history.state.forward != null && history.state.forward.indexOf('MCRMCGView') > 0 ) {
                ss = JSON.parse(ss);
                if( ss.page  > 1 ) bbs.page  = ss.page;
                if( ss.syear > 1 ) bbs.syear = ss.syear;
                if( ss.idx_position > 1 ) bbs.idx_position = ss.idx_position;
                if( ss.idx_office   > 1 ) bbs.idx_office   = ss.idx_office;
                if( ss.idx_team     > 1 ) bbs.idx_team     = ss.idx_team;
                if( ss.idx_sector     > 1 ) bbs.idx_sector     = ss.idx_sector;
            } else {
                sessionStorage.setItem('TeamEvaluationManagementList', '');
            }

             */

      // bbs.doSearch();
      bbs.doSearchMy();
      bbs.makeSearchYears();

      if (route.params.length) {
        console.log(route.params);
      }
    });

    onUnmounted(() => {
      // UnMounted
    });

    return { bbs, store };
  },
};
</script>

<style lang="scss" scoped></style>
